import React from 'react'
import { graphql, useStaticQuery } from 'gatsby'
import { capitalize } from 'lodash-es'

import { Button, Wrapper, Divider, H, P } from '@farewill/ui'
import { BREAKPOINT, COLOR } from '@farewill/ui/tokens'
import { ButtonProps } from '@farewill/ui/components/Button/types'

import {
  CHARITY_AMOUNT_RAISED,
  TELEPHONE_WILLS_APPOINTMENT_BOOKING_URL,
  TRUSTPILOT_RATING_OUT_OF_5,
  TRUSTPILOT_REVIEWS_TOTAL,
} from 'config'
import DefaultLayout from 'layouts/DefaultLayout'
import { PRODUCTS } from 'lib/products/constants'
import { roundDownToNearestHundred } from 'lib/formatting/numbers'

import AwardBanner from 'components/AwardBanner'
import BreakpointSwitch from 'components/BreakpointSwitch'
import CharitiesList from 'components/CharitiesList'
import FrequentlyAskedQuestions from 'components/FrequentlyAskedQuestions'
import PreventOrphan from 'components/PreventOrphan'
import ThreeEasySteps from 'components/ThreeEasySteps'
import TrustpilotCarousel from 'components/TrustpilotWidget/TrustpilotCarousel'
import TrustpilotReviews from 'components/TrustpilotWidget/TrustpilotReviews'

import Hero from './components/Hero'
import TrustBenefits from './components/TrustBenefits'
import NotSure from './components/NotSure'
import GetStarted from '../../components/GetStarted'
import FreeConsultation from './components/FreeConsultation'
import ContactUs from '../../components/ControlLP/ContactUs'

const CallToAction = ({
  children,
  tag: Tag = Button.Primary,
  trackingId,
  ...props
}: ButtonProps & {
  children: string
  trackingId: string
  tag?: React.FunctionComponent<React.PropsWithChildren<ButtonProps>>
}): React.ReactElement => (
  <Tag
    data-track-event="click"
    data-track-element="cta"
    data-track-slice={trackingId}
    href={TELEPHONE_WILLS_APPOINTMENT_BOOKING_URL}
    // eslint-disable-next-line react/jsx-props-no-spreading
    {...props}
  >
    {children}
  </Tag>
)

const TrustsLandingPage = (): React.ReactElement => {
  const { faqSection } = useStaticQuery(graphql`
    query {
      faqSection: contentfulFaqSection(
        contentful_id: { eq: "1UvsLYdNGOYSxjCf23cnUK" }
      ) {
        ...FaqSectionFields
      }
    }
  `)

  return (
    <DefaultLayout
      title="Specialist Will Writing Service | Property Trust"
      description={`Whether you have children from a previous relationship, stepchildren or a second marriage, make sure your will reflects your wishes. Rated ${TRUSTPILOT_RATING_OUT_OF_5} out of 5 by over ${roundDownToNearestHundred(
        TRUSTPILOT_REVIEWS_TOTAL
      )} customers on Trustpilot.`}
      product={PRODUCTS.WILLS}
      headerColor={COLOR.WHITE}
      footerShowSelectedProductLinks={false}
      meta={[
        {
          name: 'robots',
          content: 'noindex',
        },
      ]}
    >
      <Wrapper background={COLOR.WHITE}>
        <Hero
          cta={
            <CallToAction trackingId="hero">
              Book a free phone consultation
            </CallToAction>
          }
        />

        <Divider container />

        <Wrapper container>
          <BreakpointSwitch
            breakAt={BREAKPOINT.S}
            belowComponent={<TrustpilotCarousel />}
            aboveComponent={<TrustpilotReviews showTitle />}
          />
        </Wrapper>

        <TrustBenefits />

        <Wrapper paddingFromL={['S', 0, 'XL']}>
          <AwardBanner
            title="Will Writing Firm of the Year, 4 years in a row"
            paragraph="We specialise in wills, so our process is simple, personal and efficient. And because we don’t have high street offices, we pass our savings on to you."
            paragraphSize="L"
            paragraphColor={COLOR.GREY.DARK}
            showDecorativeColor
          />
        </Wrapper>

        <ThreeEasySteps
          headingMaxWidthInColumns={7}
          ctaPrompt="Find out how a trust could work for you"
          cta={
            <CallToAction trackingId="how-a-property-trust-could-save-you-thousands">
              Book a callback
            </CallToAction>
          }
          steps={[
            {
              imagePath: 'illustrations/blob-with-house',
              imageWidth: 200,
              imageWidthFromL: 200,
              opticalAdjustment: { x: '4%' },
              heading: 'Protect your spouse and safeguard your assets',
              text: 'A property trust provides for your spouse or partner in their lifetime. It safeguards your assets for your children, and should your partner need extra support, may offer protection from unexpected care home fees. ',
            },
            {
              imagePath: 'illustrations/executor-of-will_zodbff',
              imageWidth: 175,
              imageWidthFromL: 163,
              heading: 'Avoid costly legal disputes',
              opticalAdjustment: { x: '-14%' },
              text: `Families can be complicated. With a property trust, even if your spouse remarries or makes a new will after you’ve died, your share of the property is protected and cannot pass to a new spouse or their family.`,
            },
            {
              imagePath: 'illustrations/turtle-house-cactus-yellow',
              imageWidth: 175,
              imageWidthFromL: 150,
              opticalAdjustment: { x: '-15%' },
              heading: 'The affordable way to include a property trust',
              text: 'We’ve helped thousands of people to write their will, and we’ve streamlined our process to make it more efficient and affordable for our customers. So whether your family is large or small, made up of simple relationships or complex ones, we’re here to help.',
            },
          ]}
          title="How a Farewill property trust could save you thousands"
        />

        <FreeConsultation
          cta={
            <CallToAction trackingId="free-consultation">
              Book a callback
            </CallToAction>
          }
        />

        <NotSure
          cta={
            <CallToAction tag={Button.White} trackingId="not-sure" wide={false}>
              Book a callback
            </CallToAction>
          }
        />

        <Wrapper container>
          <FrequentlyAskedQuestions
            title="Frequently asked questions"
            questions={faqSection.questions}
            customAmount={4}
            selectedProduct={PRODUCTS.WILLS}
            showCallUsTodayCta
            customCta={
              <Wrapper margin={['L', 0, 0]}>
                <ContactUs />
              </Wrapper>
            }
          />
        </Wrapper>

        <Divider container />

        <Wrapper container>
          <H size="L" decorative>
            <PreventOrphan>
              {capitalize(CHARITY_AMOUNT_RAISED)} pledged to charity
            </PreventOrphan>
          </H>
          <P maxWidthInColumns={7}>
            Our generous customers have pledged {CHARITY_AMOUNT_RAISED} to our
            charity partners by leaving a gift in their wills.
          </P>

          <Wrapper
            background={COLOR.BACKGROUND.FOG}
            bordered
            borderRadius="M"
            padding={['M', 0]}
            margin={['L', 0, 0]}
          >
            <CharitiesList withMoreCharitiesText />
          </Wrapper>
        </Wrapper>

        <GetStarted
          cta={
            <CallToAction tag={Button.White} trackingId="footer-to-do-list">
              Book a callback
            </CallToAction>
          }
        />
      </Wrapper>
    </DefaultLayout>
  )
}

export default TrustsLandingPage
