import React from 'react'
import styled from 'styled-components'
import { Image, Wrapper } from '@farewill/ui'
import { GTR } from '@farewill/ui/tokens'

import TrustpilotSummary from 'components/TrustpilotWidget/TrustpilotSummary'
import HeroWithImage from 'components/HeroWithImage'

const StyledImage = styled(Image)`
  max-width: 425px;
`

const Hero = ({ cta }: { cta: React.ReactElement }): React.ReactElement => (
  <HeroWithImage
    title="Protect your family with a bespoke property trust"
    subtitle="Whether you have children from a previous relationship, stepchildren or a second marriage, make sure your will reflects your wishes."
    additionalContent={
      <>
        {cta}
        <Wrapper margin={[GTR.M, 0, 0]}>
          <TrustpilotSummary showLabelRating showLabel={false} />
        </Wrapper>
      </>
    }
    image={
      <StyledImage
        path="e_trim/illustrations/blob-with-house"
        width={400}
        widthFromM={600}
      />
    }
    titleMaxWidth="none"
  />
)
export default Hero
