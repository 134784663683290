import React from 'react'
import styled from 'styled-components'
import { graphql, useStaticQuery } from 'gatsby'

import { Grid, H, P, Wrapper } from '@farewill/ui'
import { screenMin } from '@farewill/ui/helpers/responsive'
import { COLOR, GTR } from '@farewill/ui/tokens'

import Avatar from 'components/Avatar'

type FreeConsultationProps = {
  cta: React.ReactNode
}

const StyledWrapper = styled(Wrapper)`
  ${screenMin.l`
    padding-bottom: 120px;
    padding-top: 120px;
  `}
`

const StyledQuote = styled(Wrapper)``

const StyledQuoteBox = styled(Wrapper)`
  border-radius: 16px;
`

const FreeConsultation = ({
  cta,
}: FreeConsultationProps): React.ReactElement => {
  const { author } = useStaticQuery(graphql`
    query {
      author: contentfulTeamMember(slug: { eq: "adele-davies" }) {
        id
        name
        title
        image {
          file {
            url
          }
        }
      }
    }
  `)

  return (
    <StyledWrapper container>
      <Grid>
        <Grid.Item span={12} spanFromM={7} spanFromL={6}>
          <H size="L" decorative>
            Book your free consultation with a will specialist
          </H>
          <P size="L">
            We’ll start by understanding more about your situation. You can
            choose a time that works for you, and talk to your will specialist
            from the comfort of home.
          </P>
          <P strong margin={[0, 0, GTR.M]}>
            Get started with your free consultation
          </P>
          {cta}
        </Grid.Item>
        <Grid.Item
          span={12}
          spanFromM={5}
          startColumnFromM={8}
          spanFromXL={4}
          startColumnFromL={8}
        >
          <StyledQuoteBox
            background={COLOR.ACCENT.PRIMARY_20}
            padding={[GTR.M]}
          >
            <Avatar
              imageSrc={author?.image?.file?.url}
              size={138}
              alt={author.name}
            />
            <Wrapper margin={[GTR.M, 0]}>
              <P strong margin="0">
                {author.name}
              </P>
              <P strong margin="0">
                {author.title}, Farewill
              </P>
            </Wrapper>
            <StyledQuote margin="0" tag="blockquote">
              <P color={COLOR.BLACK}>
                “I’ve worked in the legal industry for more than 19 years, with
                qualifications from Chartered Institute of Legal Executives and
                Society of Trust and Estate Practitioners.”
              </P>
            </StyledQuote>
          </StyledQuoteBox>
        </Grid.Item>
      </Grid>
    </StyledWrapper>
  )
}

export default FreeConsultation
