import React from 'react'
import styled from 'styled-components'

import { Wrapper, H, P } from '@farewill/ui'
import { COLOR, GTR } from '@farewill/ui/tokens'
import { screenMin } from '@farewill/ui/helpers/responsive'

import Texture from 'components/Texture'

type NotSureProps = {
  cta: React.ReactNode
}

const BLOB_BG_WIDTH = 355
const DOTS_BG_WIDTH = BLOB_BG_WIDTH
const FINGERPRINT_BG_WIDTH = 574

const StyledWrapper = styled(Wrapper)`
  overflow: hidden;
  position: relative;
`

const StyledContentWrapper = styled(Wrapper)`
  align-items: center;
  display: flex;
  flex-direction: column;
  position: relative;
  z-index: 2;
`

const StyledDotsTexture = styled(Texture)`
  right: 20px;
  width: ${BLOB_BG_WIDTH}px;
`

const StyledBlobWrapper = styled(Wrapper)`
  --blob-bg-width: ${BLOB_BG_WIDTH}px;
  --blob-bg-offset: -15px;
  --blob-bg-offset-scale-factor: 40vw;

  height: 325px;
  left: calc(
    -1 * (var(--blob-bg-width) + var(--blob-bg-offset)) + var(--blob-bg-offset-scale-factor)
  );
  position: absolute;
  top: 0;
  transform: translate(-35%, -19%);
  width: 400px;
  z-index: 1;

  ${screenMin.m`
    --blob-bg-offset: 35px;
  `}

  ${screenMin.l`
    --blob-bg-offset: 175px;
  `}
`

const StyledBlobTexture = styled(Texture)`
  opacity: 0.5;
`

const StyledFingerprintTexture = styled(Texture)`
  --fingerprint-bg-width: ${FINGERPRINT_BG_WIDTH}px;
  --fingerprint-bg-offset: -64px;
  --fingerprint-bg-offset-scale-factor: 40vw;

  transform: rotate(145deg);
  top: -194px;
  opacity: 0.5;
  right: calc(
    -1 * (var(--fingerprint-bg-width) + var(--fingerprint-bg-offset)) + var(--fingerprint-bg-offset-scale-factor)
  );
  width: auto; // Don't constrain to 100%.

  ${screenMin.l`
    --fingerprint-bg-offset: 86px;
  `}
`

const StyledBackgroundTextureWrapper = styled(Wrapper)`
  position: relative;
`

// [1] This BR uses a content specific breakpoint to ensure that it only affects
//     text flow when the viewport is wide enough for the entirety of the first
//     sentence to fit on the top line.
const StyledBr = styled.br`
  display: none;

  // [1]
  @media (min-width: 720px) {
    display: block;
  }
`

const NotSure = ({ cta }: NotSureProps): React.ReactElement => (
  <StyledWrapper background={COLOR.ACCENT.PRIMARY_60}>
    <StyledBackgroundTextureWrapper>
      <StyledBlobWrapper>
        <StyledBlobTexture
          path="textures/blob-yellow-3"
          width={BLOB_BG_WIDTH}
          stretch
        />
        <StyledDotsTexture
          path="textures/yellow-detailed-dots"
          width={DOTS_BG_WIDTH}
          stretch
        />
      </StyledBlobWrapper>
      <StyledFingerprintTexture
        path="textures/fingerprint-yellow-03_rolgha"
        width={FINGERPRINT_BG_WIDTH}
        stretch
      />
    </StyledBackgroundTextureWrapper>
    <StyledContentWrapper container containerVerticalPadding={GTR.XL}>
      <H centered decorative size="L">
        Not sure if you need a trust in your will?
      </H>
      <P
        centered
        color={COLOR.BLACK}
        margin={[0, 0, GTR.XL]}
        maxWidthInColumns={7}
        size="L"
      >
        Book a call with a specialist to talk through your situation. We’ll
        listen and explain how we can support you.
      </P>
      {cta}
      <P
        centered
        color={COLOR.BLACK}
        margin={[GTR.XL, 0, 0]}
        maxWidthInColumns={7}
      >
        We provide guides to help you understand how tax may impact your legacy.{' '}
        <StyledBr />
        For specialist tax advice, we always recommend speaking to your
        financial advisor.
      </P>
    </StyledContentWrapper>
  </StyledWrapper>
)

export default NotSure
