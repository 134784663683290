import React from 'react'
import { Wrapper, H } from '@farewill/ui'
import { COLOR } from '@farewill/ui/tokens'

import WrapperWithEdge from 'components/WrapperWithEdge'
import PreventOrphan from 'components/PreventOrphan'

type GetStartedProps = {
  cta: React.ReactNode
  heading?: string
}

const GetStarted = ({
  cta,
  heading = 'Ready to tick your will off your to-do list?',
}: GetStartedProps): React.ReactElement => (
  <WrapperWithEdge direction="up" edgeBackgroundColor={COLOR.WHITE}>
    <Wrapper container containerPaddingTop="L">
      <Wrapper centered maxWidth={550} margin={[0, 'auto']}>
        <H size="L" decorative>
          <PreventOrphan>{heading}</PreventOrphan>
        </H>
      </Wrapper>

      <Wrapper margin={['L', 'auto', 0]} centered>
        {cta}
      </Wrapper>
    </Wrapper>
  </WrapperWithEdge>
)

export default GetStarted
