import React from 'react'
import styled from 'styled-components'
import { Wrapper, Grid, Image, H, P } from '@farewill/ui'
import { COLOR, GTR } from '@farewill/ui/tokens'
import { screenMin } from '@farewill/ui/helpers/responsive'
import type { WrapperProps } from '@farewill/ui/components/Wrapper/types'

import WrapperWithEdge from 'components/WrapperWithEdge'
import PreventOrphan from 'components/PreventOrphan'
import BreakpointSwitch from 'components/BreakpointSwitch'
import Texture from 'components/Texture'

const CARD_MIN_WIDTH = 360
const CARD_MAX_WIDTH = 460
/**
 * The minimum screen width at which we can fit 3 cards with all spacing
 */
const GRID_BREAKPOINT =
  CARD_MIN_WIDTH * 3 + parseInt(GTR.M, 10) * (3 - 1) + parseInt(GTR.L, 10) * 2

const StyledRoot = styled(WrapperWithEdge)`
  position: relative;
  overflow: hidden;
`

const StyledSwoopTexture = styled(Texture)`
  top: 50%;
  transform: translateY(-27%);

  ${screenMin.l`
  `}

  ${screenMin.xl`
  `}
`

const StyledDotsTexture = styled(Texture)`
  // transform: scaleX(-1);
  top: 270px;
  right: -10px;

  @media (min-width: ${GRID_BREAKPOINT}px) {
    top: 70px;
  }
`

const StyledContentWrapper = styled(Wrapper)`
  position: relative;
  z-index: 2;
`

const StyledBenefitCard = styled(Wrapper)`
  border-radius: 16px;
  display: flex;
  flex-direction: column;
  align-items: center;
  max-width: ${CARD_MAX_WIDTH}px;

  ${screenMin.m`
    margin-left: auto;
    margin-right: auto;
  `}
`

const StyledGridItem = styled(Grid.Item)`
  display: flex;
`

const StyledSubtitle = styled(P)`
  ${screenMin.m`
    max-width: 55ch;

    @supports (text-wrap: balance) {
      max-width: none;
      text-wrap: balance;
    }
  `}
`

type Benefit = {
  illustration: string
  title: string
  body: string
}

const BENEFITS: Array<Benefit> = [
  {
    illustration: 'illustrations/blob-with-children',
    title: 'Protect your family',
    body: 'Protect your assets for your children or stepchildren and provide for your spouse or partner in their lifetime.',
  },
  {
    illustration: 'illustrations/wills-safeguard-assets',
    title: 'Safeguard your assets',
    body: 'Make the most of available tax allowances and help ring-fence your assets for the future.',
  },
  {
    illustration: 'illustrations/blob-couples-exchange-bw',
    title: 'Save on legal fees',
    body: 'A more affordable way to add a trust, avoid legal disputes and make sure your children get their fair share.',
  },
]

type BenefitCardProps = WrapperProps & {
  benefit: Benefit
}

const BenefitCard = ({
  benefit,
  ...props
}: BenefitCardProps): React.ReactElement => (
  <StyledBenefitCard
    background={COLOR.WHITE}
    padding={[GTR.L, GTR.M]}
    // eslint-disable-next-line react/jsx-props-no-spreading
    {...props}
  >
    <Image path={benefit.illustration} width={240} />
    <H size="S" margin={[GTR.S, 0]}>
      {benefit.title}
    </H>
    <P centered margin={0}>
      {benefit.body}
    </P>
  </StyledBenefitCard>
)

const TrustBenefits = (): React.ReactElement => (
  <StyledRoot direction="down" edgeBackgroundColor={COLOR.WHITE}>
    <StyledSwoopTexture
      path="textures/sketchy-swoop-yellow-03_k3g7yy"
      width={221}
      stretch
    />
    <StyledDotsTexture
      path="textures/yellow-detailed-dots"
      width={330}
      stretch
    />

    <StyledContentWrapper container noTrim>
      <H size="L" decorative centeredFromM>
        <PreventOrphan>A will with a property trust, just £440</PreventOrphan>
      </H>
      <StyledSubtitle
        centeredFromM
        color={COLOR.BLACK}
        margin={[0, 'auto']}
        size="L"
      >
        With Farewill, prices are transparent and fixed upfront. No hidden
        extras or hourly rates – just a bespoke will, written with an
        experienced will writer.
      </StyledSubtitle>

      <BreakpointSwitch
        breakAt={GRID_BREAKPOINT}
        belowComponent={
          <Wrapper margin={[GTR.XL, 0, GTR.M]} marginFromL={[GTR.XL, 0]}>
            {BENEFITS.map((benefit) => (
              <Wrapper key={benefit.title} margin={[0, 0, GTR.M]}>
                <BenefitCard benefit={benefit} />
              </Wrapper>
            ))}
          </Wrapper>
        }
        aboveComponent={
          <Grid
            margin={[GTR.XL, 0, 0]}
            marginFromM={[GTR.XL, 0]}
            gap={[0, GTR.M]}
            gapFromL={[0, GTR.L]}
            gapFromXL={[0, GTR.XL]}
          >
            {BENEFITS.map((benefit) => (
              <StyledGridItem span={4} spanFromL={4} key={benefit.title}>
                <BenefitCard
                  benefit={benefit}
                  key={benefit.title}
                  margin={[0, 0, GTR.M]}
                />
              </StyledGridItem>
            ))}
          </Grid>
        }
      />
    </StyledContentWrapper>
  </StyledRoot>
)

export default TrustBenefits
